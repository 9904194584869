.container {
  height: 100%;
  position: relative;
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  width: 100%;
}

.eucui-cnbl-custom-class-light-mode * {
  background-color: #FAF9F7;
  color: black;
  border-bottom: 0px !important;
}
