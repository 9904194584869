:root {
  --toolbar-height: 43px;
}

.toolbar {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  background: var(--color-background-control-default-4pa05r, #ffffff);
  list-style-type: none;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  height: var(--toolbar-height);
  transition: height 0.4s;
  overflow: hidden;
}
.toolbar.collapsed {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.toolbar.iconLabelsDisabled {
  height: 26px;
}

.toolbar-button, .toolbar-item-group-label-container {
  width: 100%;
  background: none;
  border: none;
  color: var(--color-text-body-default-ajf1h5, #000716);
  line-height: 22px;
  padding: 1px;
  padding-top: var(--space-scaled-xxxs-lo883m, 2px);
  margin: 0;
  --webkit-appearance: none;
}
.toolbar-button.labeled, .labeled.toolbar-item-group-label-container {
  height: var(--toolbar-height);
  font-weight: 400;
  font-size: 14px;
}
.toolbar-button:hover, .toolbar-item-group-label-container:hover {
  border-color: var(--color-text-link-hover-kololx, #033160);
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  cursor: pointer;
}
.toolbar-button:active:not(:disabled), .toolbar-item-group-label-container:active:not(:disabled) {
  color: var(--color-border-item-focused-ap3b6s, #0972d3) !important;
  font-weight: unset !important;
}
.toolbar-button.selected, .selected.toolbar-item-group-label-container {
  color: var(--color-border-item-focused-ap3b6s, #0972d3);
  font-weight: bold;
}
.toolbar-button.disabled, .disabled.toolbar-item-group-label-container {
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
  cursor: not-allowed !important;
}
.toolbar-button.loading, .loading.toolbar-item-group-label-container {
  cursor: wait !important;
}

.toolbar-button-label, .toolbar-item-group-label {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 150%;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size: 12px;
}

.toolbar-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.horizontal-menu {
  margin: 0;
  list-style-type: none;
  margin-top: var(--space-scaled-xxxs-lo883m, 2px) !important;
  border-radius: var(--space-scaled-xxs-95dhkm, 4px);
  list-style-type: none;
  background: var(--color-background-control-default-4pa05r, #ffffff);
  width: 100vw;
  z-index: 98;
  padding: var(--space-scaled-s-cu1hzn, 12px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  flex-wrap: wrap;
  gap: var(--space-scaled-xs-6859qs, 8px);
}

.dropdown {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: var(--space-scaled-xxxs-lo883m, 2px) !important;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  list-style-type: none;
  background: var(--color-background-dropdown-item-default-fzdqsg, #ffffff);
  z-index: 101;
  border: solid 3px var(--color-border-control-default-m3lmsh, #7d8998);
}

.toolbar-dropdown-menu-item button {
  background: none;
  border: none;
  padding: 6px 22px 6px 22px;
  border-radius: var(--border-radius-dropdown-ujknaf, 8px);
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-scaled-xs-6859qs, 8px);
}
.toolbar-dropdown-menu-item button:hover {
  background: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
  cursor: pointer;
}

.hidden {
  display: none;
}

.toolbar-control-wrapper {
  width: 100%;
}

.toolbar-button-list-item {
  height: 100%;
}

.toolbar-dropdown-check-icon {
  display: inline-flex;
  align-items: center;
  width: 24px;
  justify-content: center;
}

.toolbar-button-list-item-group {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toolbar-button-pair {
  display: flex;
  align-items: center;
}

/*
 * Container type toolbar item content doesn't have `position: absolute`.
 * This is because a container item type content should be inside the
 * floating toolbar, so that the content should not go outside the boundary.
 */
.container-item-content {
  /** To match the width of toolbar ul element (https://stackoverflow.com/a/48226415) */
  display: flex;
  padding: 20px;
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  min-height: 40px;
}
.container-item-content .container_item_wrapper {
  /** To match the width of toolbar ul element (https://stackoverflow.com/a/48226415) */
  flex-grow: 1;
  width: 0;
}
.container-item-content .close_content {
  z-index: 100;
  position: absolute;
  transform: translate(0, -10px);
  right: 20px;
  opacity: 0;
}
.container-item-content:hover .close_content {
  opacity: 1;
}
