/* Reference: https://www.hobo-web.co.uk/best-screen-size/ */
.center-horizontal-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

hr.vertical {
  height: 100%;
  margin: 0;
  border: 1px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

svg.custom_icon {
  vertical-align: middle;
  fill: currentColor;
  font: inherit;
  display: inline-block;
  user-select: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

button:hover {
  cursor: pointer;
}

.content_preview {
  border-radius: var(--border-radius-input-txq8zy, 8px);
  border: 2px solid var(--color-border-input-default-e47bkv, #7d8998);
  background-color: var(--color-background-input-disabled-wu9j6u, #e9ebed);
  padding: 10px;
  height: 150px;
  transition: border 200ms ease-in-out;
}
.content_preview.focused {
  border-color: var(--color-border-item-focused-ap3b6s, #0972d3);
}
.content_preview .placeholder {
  color: var(--color-text-input-placeholder-j53j3c, #5f6b7a);
}
.content_preview pre.clipboard_text {
  margin: 0;
  font-weight: 800;
  color: var(--color-text-interactive-default-eg5fsa, #414d5c);
  font: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  overflow: auto;
  height: 100%;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

@media screen and (max-height: 640px) {
  .content_preview {
    height: 50px;
  }
}
@media screen and (min-height: 640px) and (max-height: 896px) {
  .content_preview {
    height: 100px;
  }
}
.popover {
  visibility: hidden;
  border: 2px solid #9ba7b6;
  border-radius: var(--border-radius-popover-8g1bwn, 8px);
  padding: 10px 15px;
  background-color: var(--color-background-dropdown-item-default-fzdqsg, #ffffff);
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  bottom: -4px;
  pointer-events: none;
}
.popover.visible {
  visibility: visible;
  opacity: 1;
}

.popover-arrow {
  height: 1rem;
  width: 1rem;
  pointer-events: none;
  bottom: 0;
  margin-bottom: -1rem;
  position: absolute;
  left: 0px;
  transform: translate(85px);
}
.popover-arrow::before, .popover-arrow::after {
  border-width: 0.4rem 0.5rem 0 0.5rem;
  border-style: solid;
  content: "";
  display: block;
  margin: auto;
  position: absolute;
  height: 0;
  width: 0;
}
.popover-arrow::before {
  border-color: #9ba7b6 transparent transparent transparent !important;
  top: 2px;
}
.popover-arrow::after {
  border-color: var(--color-background-dropdown-item-default-fzdqsg, #ffffff) transparent transparent transparent !important;
}
