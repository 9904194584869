/* Reference: https://www.hobo-web.co.uk/best-screen-size/ */
.center-horizontal-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

hr.vertical {
  height: 100%;
  margin: 0;
  border: 1px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

svg.custom_icon {
  vertical-align: middle;
  fill: currentColor;
  font: inherit;
  display: inline-block;
  user-select: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

button:hover {
  cursor: pointer;
}

:root {
  --tutorial-padding-left: 350px;
  --tutorial-padding-bottom: 300px;
}

.floating_toolbar_wrapper {
  position: absolute;
  display: inline-block;
  /** Add padding to both left and bottom of toolbar to make enough room for tutorial tooltip */
}
.floating_toolbar_wrapper.tutorial {
  padding-left: var(--tutorial-padding-left);
  padding-bottom: var(--tutorial-padding-bottom);
}
.floating_toolbar_wrapper .floating_toolbar {
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.toolbar {
  /** Remove height change animation for floating toolbar. */
  transition: unset !important;
}

.toolbar-wrapper.collapsed {
  /**
   * Toolbar component will change the height of toolbar once it is collapsed
   * but there is a delay and it will change the width before updating the height.
   * This will cause some unexpected toolbar behavior and scrollbar on the screen.
   */
  display: none;
}

.toggle_expand_collapse {
  position: absolute;
  float: left;
  left: 50%;
  transform: translate(-50%, -5px);
  opacity: 0;
  transition: opacity 100ms;
  /* Notification list z-index is 99 */
  z-index: 100;
}
.toggle_expand_collapse button {
  cursor: pointer;
  color: var(--color-text-body-default-ajf1h5, #000716);
  background: var(--color-background-control-default-4pa05r, #ffffff);
  border-radius: 50%;
  border: 1px solid #C3C4C8;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.17);
}
.toggle_expand_collapse svg.expand, .toggle_expand_collapse svg.collapse {
  width: 12px;
}

.floating_toolbar_wrapper:hover:not(.dragging) .toggle_expand_collapse {
  opacity: 1;
}
.floating_toolbar_wrapper.dragging:not(.show-floating-notification) .toggle_expand_collapse {
  display: none;
}
.floating_toolbar_wrapper.bottom .toggle_expand_collapse {
  position: absolute;
  top: -10px;
  left: 50%;
  bottom: unset;
  transform: translateX(-50%);
}
.floating_toolbar_wrapper.tutorial .toggle_expand_collapse {
  padding-left: var(--tutorial-padding-left);
  bottom: calc(var(--tutorial-padding-bottom) - 15px);
}

.hotspot1 {
  position: absolute;
  left: calc(var(--tutorial-padding-left) - 20px);
  top: 16px;
}

.hotspot2 {
  position: absolute;
  left: 49%;
  padding-left: calc(var(--tutorial-padding-left) / 2);
  bottom: calc(var(--tutorial-padding-bottom) - 18px);
}

.hotspot3 {
  position: absolute;
  left: 2%;
}

.hotspot4 {
  position: absolute;
  right: 241px; /** Use a hardcoded value from right since peference tab is always the 3rd from right. */
  bottom: calc(var(--tutorial-padding-bottom) - 18px);
}

.floating_handle {
  background-color: #282f38;
  height: 11px;
  transition: height 300ms;
  color: white;
  cursor: grab;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
}
.floating_handle:active {
  cursor: grabbing;
}

.minimized-view-wrapper {
  height: 0;
  overflow: hidden;
  background: var(--color-background-control-default-4pa05r, #ffffff);
  /** Need to keep the left and right padding to calculate the width of minimized view */
  padding: 0 15px;
}
.minimized-view-wrapper.collapsed {
  height: auto;
  padding: 3px 15px;
}
.minimized-view-wrapper.collapsed .minimized-view {
  visibility: visible;
}
.minimized-view-wrapper .minimized-view {
  visibility: hidden;
  display: inline-flex;
  padding: 0 5px;
}
