.application-list-button {
  width: 16.6rem;
  height: 4.375rem;
  background: none;
  color: var(--color-text-body-default-ajf1h5, #000716);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0px;
  padding-left: 1.07rem;
  border: 1px solid var(--color-border-input-default-e47bkv, #7d8998);
}

.application-list-button-selected {
  border: var(--space-static-xxxs-k3qmdh, 2px) solid var(--color-border-button-normal-default-djv8f5, #0972d3) !important;
}

.application-list-button:hover {
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
  background: var(--color-background-dropdown-item-hover-mqsxkv, #f4f4f4);
}

.application-list-button-label-container {
  max-height: var(--space-scaled-xxl-pfgv96, 32px);
  display: flex;
  align-items: flex-start;
}

.application-list-button-label {
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-left: var(--space-scaled-m-pv0fmt, 16px);
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  max-width: 170px;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
}

.application-list-button-icon > * {
  width: 2rem;
  height: 2rem;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  border-radius: 1rem;
}

.application-list-button-large-icon {
  width: 11.625rem;
  height: 12.7875rem;
  flex-shrink: 0;
  background: var(--color-background-container-content-i8i4a0, #ffffff);
  border: solid var(--space-static-xxxs-k3qmdh, 2px) var(--color-border-container-top-srcvx4, transparent);
  color: var(--color-text-body-default-ajf1h5, #000716);
  border-radius: 1rem;
  padding: var(--space-scaled-m-pv0fmt, 16px);
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px rgba(65, 77, 92, 0.2), 0px 1px 14px 0px rgba(0, 7, 22, 0.14);
}

.application-list-button-large-icon:hover {
  border: var(--space-static-xxxs-k3qmdh, 2px) solid var(--color-border-button-normal-default-djv8f5, #0972d3);
}

.application-list-button-large-icon:hover .application-list-button-label-large-icon {
  font-weight: bold;
  font-size: 0.83rem;
}

.application-list-button-label-large-icon {
  overflow: hidden;
  max-width: 9.5rem;
  line-height: 1.5rem;
  max-height: 3rem;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-style: normal;
  white-space: pre-wrap;
  white-space: break-spaces;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-size: 0.875rem;
  letter-spacing: 0.00938rem;
}

.large-thumbnail-container {
  height: 5.8125rem;
  width: 5.8125rem;
}

.application-list-button-icon-large-icon > * {
  max-height: 100%;
  width: 100%;
  border-radius: 1rem;
  margin: auto;
}

.application-list-button-icon-large-icon {
  height: 100%;
  width: 100%;
  display: flex;
}

.empty-thumbnail {
  height: 100%;
  border-radius: 1rem;
  aspect-ratio: 1/1;
  margin: 0 auto;
}

.empty-thumbnail > * {
  height: 100%;
  border-radius: 1rem;
}

.thumbnail-capital-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  font-size: 3rem;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
}

.large-application-button {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 0.88rem;
}

.application-list-and-title-container {
  display: flex;
  flex-direction: column;
}

.application-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.62rem;
  list-style-type: none;
  padding: 0;
}

.application-list-title {
  padding-left: 0.31rem;
}
