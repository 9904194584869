/* Reference: https://www.hobo-web.co.uk/best-screen-size/ */
.center-horizontal-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

hr.vertical {
  height: 100%;
  margin: 0;
  border: 1px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

svg.custom_icon {
  vertical-align: middle;
  fill: currentColor;
  font: inherit;
  display: inline-block;
  user-select: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

button:hover {
  cursor: pointer;
}

.fn_key {
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-weight: 800;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  padding: 4px 12px;
  border: 2px solid var(--color-border-input-default-e47bkv, #7d8998);
  background-color: var(--color-background-input-default-u57378, #ffffff);
  color: var(--color-text-label-gxskdy, #000716);
  font-size: 14px;
  line-height: 22px;
}
.fn_key:hover {
  border-color: var(--color-border-item-focused-ap3b6s, #0972d3);
  color: var(--color-border-item-focused-ap3b6s, #0972d3);
}
.fn_key:active {
  background-color: var(--color-background-item-selected-ebt4bi, #f2f8fd);
}
.fn_key.selected {
  background-color: var(--color-background-button-primary-default-pwn1g1, #0972d3);
  border-color: var(--color-border-item-selected-ppkssz, #0972d3);
  color: var(--color-text-button-primary-default-340wh6, #ffffff);
}

.fn_key svg {
  width: 1em;
  height: 1em;
}
