.branded-horizontal-split-layout-mobile-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
}

.branded-horizontal-split-layout-mobile-upper-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.branded-horizontal-split-layout-mobile-header, .branded-horizontal-split-layout-mobile-footer {
  width: 100%;
}

.branded-horizontal-split-layout-mobile-main-content {
  width: 100%;
}

.branded-horizontal-split-layout-mobile-brand-logo {
  height: 36px;
  align-self: center;
  margin: 2rem;
}

.branded-horizontal-split-layout-brand-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
