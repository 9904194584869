.path_separator {
  margin-right: var(--space-scaled-xs-6859qs, 8px);
  margin-bottom: 2px;
  vertical-align: middle;
  fill: currentColor;
  font: inherit;
  display: inline-block;
  user-select: none;
  width: 14px;
  height: 14px;
}

ol.breadcrumb {
  margin: 5px 0 0 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}

li.breadcrumb_item {
  float: left;
  list-style: none;
}

.dropzone_border {
  border: dashed var(--space-static-xxs-jy4jgd, 4px) var(--color-charts-line-axis-yo865k, #d1d5db);
  box-sizing: border-box;
}

.file-explorer-table-container {
  overflow: auto;
}

.screen-reader-only {
  opacity: 0;
  position: absolute;
}
