.google-signin-button-icon {
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-weight: 480;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: inline-block;
  padding-top: 0px;
  padding-right: var(--space-scaled-l-t03y3z, 20px);
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: transparent;
  color: var(--color-text-button-normal-default-u8647m, #0972d3);
  line-height: var(--space-scaled-xxl-pfgv96, 32px);
  border: var(--space-static-xxxs-k3qmdh, 2px);
  border-radius: var(--border-radius-button-axewzv, 20px);
  border-color: var(--color-border-button-normal-default-djv8f5, #0972d3);
  border-style: solid;
}
.google-signin-button-icon:hover {
  border-color: var(--color-background-button-primary-hover-qu1zio, #033160);
  color: var(--color-text-button-normal-hover-kxmvcc, #033160);
}
.google-signin-button-icon.selected {
  background-color: var(--color-background-button-primary-default-pwn1g1, #0972d3);
  border-color: var(--color-border-button-normal-default-djv8f5, #0972d3);
  color: var(--color-text-button-primary-default-340wh6, #ffffff);
}
.google-signin-button-icon .google-space-between {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: var(--space-static-xs-9adq92, 8px);
}

.image-container {
  display: flex;
  align-items: center;
  padding: var(--space-static-xxxs-k3qmdh, 2px);
  border-top-left-radius: var(--border-radius-button-axewzv, 20px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: var(--border-radius-button-axewzv, 20px);
  background-color: white;
  justify-content: center;
}

.google-icon-svg {
  border-top-left-radius: var(--border-radius-button-axewzv, 20px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: var(--border-radius-button-axewzv, 20px);
  background-color: white;
  margin-left: var(--space-static-xxs-jy4jgd, 4px);
  margin-right: var(--space-static-xxs-jy4jgd, 4px);
}
