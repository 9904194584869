/* Reference: https://www.hobo-web.co.uk/best-screen-size/ */
.center-horizontal-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

hr.vertical {
  height: 100%;
  margin: 0;
  border: 1px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

svg.custom_icon {
  vertical-align: middle;
  fill: currentColor;
  font: inherit;
  display: inline-block;
  user-select: none;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

button:hover {
  cursor: pointer;
}

.toolbar.collapsed {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.toolbar.collapsed > * {
  display: none;
}

.toolbar-expand-button-container {
  height: 15px;
  width: 15%;
  display: flex;
  position: absolute;
  z-index: 99;
  float: left;
  left: 50%;
  transform: translate(-50%, 0px);
  opacity: 0.4;
}

.toolbar-expand-button-container.collapsed {
  opacity: 0.4;
}

.toolbar-expand-button {
  background: white;
  border: none;
  width: 100%;
  --webkit-appearance: none;
  border-radius: var(--border-radius-button-axewzv, 20px);
  box-shadow: 0px 4px 20px rgba(0, 7, 22, 0.12);
}

.docked-toolbar-wrapper:hover .toolbar-expand-button-container {
  opacity: 0.8;
}

@media screen and (max-width: 1024px) {
  .docked-toolbar-wrapper .toolbar-button-label {
    display: none;
  }
}

.hotspot5 {
  position: absolute;
  left: 2%;
}

.toolbar-button-container-Preferences {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Preferences {
  position: absolute;
  top: 60.5px;
  z-index: 1000;
}
.Preferences.iconLabelsDisabled {
  top: 45px;
}

.docked-toolbar-notification-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}
