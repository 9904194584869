[data-size=cover] {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.progress-bar {
  height: 4px;
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  height: 100%;
  transition: width 0.2s linear 0s;
  transform-origin: left center 0;
}

.progress-bar.indeterminate > .progress-bar-value {
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
